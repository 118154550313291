// Background colours
$backgroundColour: #ffffff;
$codeBackgroundColour: #fafafa;
$featureBackgroundColour: #f9fafa;
$accentColour: #0046fe;

// Text colours
$headingColour: #041a54;
$bodyColour: #041a54;
$linkColour: #0046fe;
$hoverColour: darken($linkColour, 15%);
$focusColour: orange;
$captionColour: #edf2f5;
$white: #ffffff;

// Typography
$bodytype: (
  font-family: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  regular: 400,
  bold: 700,
  italic: italic,
  cap-height: 0.75
);

$headingtype: (
  font-family: '"Helvetica Neue", Helvetica, Arial, sans-serif',
  regular: 400,
  bold: 700,
  cap-height: 0.75
);

$monospacetype: (
  font-family: 'Menlo, monospace',
  regular: 400,
  cap-height: 0.68
);

// Tweaks
li::before { color: $accentColour }
body .button { border-radius: .1em; background: $accentColour }
.nav--footer { margin: 0 auto }

@import "alembic";
